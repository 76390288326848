<template>
  <div>
    <b-card title="Perubahan Surat Komunikasi">
      <b-row>
        <b-col md="12">
          <b-form class="p-2" @submit.prevent="SaveEditIntComm()">
          <b-form-group
            label="Keterangan Komunikasi"
            label-for="komunikasi-tipe"
          >
            <v-select
              id="komunikasi-tipe"
              v-model="com_type"
              placeholder="Keterangan Komunikasi"
              label="statusName"
              :options="IntCommReff"
              class="mb-2"
            />
          </b-form-group>
          <b-form-group
            label="Tanggal"
            label-for="date"
          >
            <flat-pickr
              id="date"
              v-model="date"
              class="form-control"
              placeholder="Pilih Tanggal"
            />
          </b-form-group>
          <b-form-group
            label="Catatan"
            label-for="description"
          >
            <b-form-textarea
              id="description"
              v-model="description"
              rows="3"
              placeholder="Ketik Catatan"
              class="mb-2"
            />
          </b-form-group>
          <b-form-group>
              <b-card-text class="my-1">
                File : <strong>{{ file_name }}</strong>
              </b-card-text>
            </b-form-group>
          <b-form-group
            label="Lampiran"
            label-for="attachment_file"
          >
            <b-form-file
              id="attachment_file"
              v-model="attachment_file"
              accept="image/jpeg, application/pdf"
              placeholder="Klik disini untuk memilih file"
              @change="onChange"
            />

            <b-card-text class="my-1">
              Ukuran File : <strong>{{ attachment_file ? (attachment_file.size / 1048576).toFixed(2) + ' MB': ''  }} / 3 MB</strong>
            </b-card-text>
          </b-form-group>
            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
                block
              >
                <b-spinner
                  v-show="isSpinner"
                  class="ml-auto"
                  :hidden="!isSpinner"
                  small
                  type="grow"
                />
                <div :hidden="isSpinner">
                  <span class="align-middle">Simpan</span>
                </div>
              </b-button>
              <router-link
                  :to="{
                      name: 'comunication',
                    }"
                >
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
              >
                Batal
              </b-button>
              </router-link>
            </div>
          </b-form>
        </b-col>
      </b-row>
    </b-card>
    <b-modal
      ref="v-modal"
      ok-variant="danger"
      modal-class="modal-danger"
      hide-footer
      centered
      title="Peringatan !"
    >
      <b-card-text> {{ message }} </b-card-text>
    </b-modal>

  </div>
</template>

<script>
import {
  BAlert,
  BModal,
  BBadge,
  BCard,
  BCardTitle,
  BCardText,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BButton,
  BSpinner,
  BOverlay,
  BTable,
  BPagination,
  BInputGroup,
  BFormSelect,
  BFormInput,
  BInputGroupAppend,
  BDropdown,
  BDropdownItem,
  VBToggle,
  BFormTextarea,
  BFormFile
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import { heightFade } from "@core/directives/animations";
import flatPickr from "vue-flatpickr-component";
import axios from "axios";
//import AddCutiSidebar from "./AddCutiSidebar.vue";

export default {
  components: {
    BAlert,
    BModal,
    BBadge,
    BCard,
    BCardTitle,
    BCardText,
    vSelect,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BButton,
    BSpinner,
    BOverlay,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BDropdown,
    BDropdownItem,
    BTable,
    BFormTextarea,
    BFormFile,
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
    // AddCutiSidebar,
    flatPickr,
  },
  directives: {
    Ripple,
    heightFade,
    "b-toggle": VBToggle,
  },
  data() {
    return {
      isSpinner: false,
      isDisableByVerified: true,
      message: null,
      employeeData: JSON.parse(localStorage.getItem("userData")),
      IntCommCode: this.$route.params._id,
      com_type: null,
      IntCommReff: [],
      date: null,
      description: null,
      attachment_file: null,
      file_name: null,
    };
  },
  mounted() {
    this.getIntCommCode()
  },
  created() {
    this.getIntCommData()
  },
  methods: {
    getIntCommCode(){
      axios.get('/intcom/komTypeReff').then(response => {
        console.log(response.data.data)
        this.IntCommReff = response.data.data
      })
    },
    getIntCommData() {
      console.log(this.IntCommCode);
      if(this.IntCommCode !== null) {
        axios.post('intcom/detil', {
        intcom_code: this.IntCommCode
        })
        .then(response => {
          console.log(response);
          this.com_type = response.data.data.com_type
          this.date = response.data.data.date
          this.description = response.data.data.description
          this.file_name = response.data.data.attachment_file
          this.detil = response.data
        })
      } else {
        console.log(error);
      }
    },
    onChange(event){
      this.attachment_file = event.target.files[0]
    },
    SaveEditIntComm() {
      console.log(this.com_type.statusCode);
      const fdata = new FormData();
      fdata.append('_method', 'patch')
      fdata.append('intcom_code', this.IntCommCode)
      fdata.append('date', this.date)
      fdata.append('com_type_code', this.com_type.statusKode)
      fdata.append('description', this.description)
      fdata.append('attachment_file', this.attachment_file)

      // let config = {
      //     header : {
      //     'Content-Type' : 'multipart/form-data'
      //   }
      // } 

      console.log(fdata);
      //this.isSpinner = true
      
      axios.post('/intcom/update', fdata)
      // .then(res => { console.log(res);})
        .then(response => {
          console.log(response);
          if (response.status === 200) {
            console.log(response)
            this.$swal({
              icon: 'success',
              title: 'Data berhasil disimpan',
              timer: 1500,
              showCancelButton: false,
              showConfirmButton: false,
            }).then(
              () => {},
              // handling the promise rejection
              dismiss => {
                if (dismiss === 'timer') {
                  // console.log('I was closed by the timer')
                }
              },
            )
          this.$router.push({ name: 'comunication' }).catch(() => {})
          } else {
            console.log(response)
            this.$refs['v-modal'].show()
            this.isValid = true
            this.message = response.data.message
            // console.log(this.message);
          }
        })
        .catch(error => {
          // console.log(error);
          if (error.response.status === 400) {
            this.$refs['v-modal'].show()
            console.log(error.response.data.message)
            this.message = error.response.data.message
          } 
          if (error.response.status === 401) {
          this.$router.push({ name: "not-authorized" }).catch(() => {});
          } 
          else {
            console.log(error)
          }
        })
        .finally(() => {
          this.isSpinner = false
          // this.resetForm()
          // this.$refs['sidebar-right'].hide()
          // this.$parent.fetchDataList()
        })
    },
    ToastDataNotFound() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Data tidak ditemukan.",
          icon: "BellIcon",
          variant: "danger",
        },
      });
    },
    ToastDataFound() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Data ditemukan.",
          icon: "BellIcon",
          variant: "success",
        },
      });
    },
    // validationFormInfo() {
    //   this.$refs.infoRules.validate().then(success => {
    //     if (success) {
    //       this.getLeaveEmployee()
    //     } else {
    //       this.$refs['v-modal'].show()
    //     }
    //   })
    // },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
